import './styles/main.module.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './pages/login';
import Register from './pages/register';
import Mail from './pages/mail';
import Compose from './pages/compose';
import Settings from './pages/settings';
import Aliases from './pages/aliases';
import Domains from './pages/domains';
import Filters from './pages/filters';
import Landing from './pages/landing';
import GmailImport from './pages/gmail-import';
import ExternalUser from "./pages/external-user";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/mail" element={<Mail/>}/>
                <Route path="/compose" element={<Compose/>}/>
                <Route path="/settings" element={<Settings/>}/>
                <Route path="/aliases" element={<Aliases/>}/>
                <Route path="/domains" element={<Domains/>}/>
                <Route path="/filters" element={<Filters/>}/>
                <Route path="/gmail-import" element={<GmailImport/>}/>
                <Route path="/ext-user" element={<ExternalUser/>}/>
            </Routes>
        </Router>
    );
}

export default App;
