import React, {useEffect, useRef, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {toast} from 'react-toastify';
import {
    base64ToByteArray,
    base64ToUtf8,
    createFile,
    downloadFile,
    formatDateForMailDisplay, parseContactTypeEmail,
    userIsLoggedIn
} from "../utils/utils";
import Navigation from "../components/navigation";
import Header from "../components/header";
import {useNavigation} from "../hooks/navigation"
import StandardTextInput from "../components/basic/StandardTextInput";

import Styles from "../styles/main.module.css";

import {
    deleteFullThreadOnServer,
    deleteMessage,
    fetchAttachmentData,
    fetchMessageBody, fetchThread,
    listing, listingForCache, markDeviceSetupComplete, markMessageAsReadBackend, moveMessageOnServer
} from "../server-calls/index"
import StateManager from "../redux/StateManager";
import {
    CACHE_CONF,
    HTML_MESSAGE_IDENTIFIER,
    SORT_OPTIONS,
    SORT_ORDER,
    TOAST_CONTAINER_ID
} from "../utils/constants";
import _ from "lodash";
import StandardDropdown from "../components/basic/StandardDropDown";

import {decryptPGPMessage} from "../business/encryption/pgp";
import {
    getSearchPaginatedResults, initMailsCacheDB, parseAndAddEmailInCache,
    readMessageByUid,
    searchForWordsInBox, updateCache
} from "../idb";
import md5 from "md5";
import {Helmet} from "react-helmet-async"
import {
    createSubstring,
    decodeIfMimeEncoded,
    decryptMessageAttachmentNames,
    decryptMessageBody,
    decryptMessageSubject
} from "../mail/utils";
import {initializeSocket} from "../socket";
import {getDeviceId} from "../cookie";

export default function Mail() {
    const {redirect, reloadPage} = useNavigation()
    let [mailData, setMailData] = useState([]);
    let [mailboxes, setMailboxes] = useState([]);
    let [sortOptions] = useState(SORT_OPTIONS)
    let [sortKey, setSortKey] = useState(StateManager.getMailSortingKey())
    let [sortOrders] = useState(SORT_ORDER)
    let [sortOrder, setSortOrder] = useState(StateManager.getMailSortingOrder())
    let [currentMbox, setCurrentMailbox] = useState("INBOX")
    let [currentPage, setCurrentPage] = useState(1)
    let [totalPages, setTotalPages] = useState(1)
    let [hasNextPage, setHasNextPage] = useState(false)
    let [hasPreviousPage, setHasPreviousPage] = useState(false)
    let [pageSize] = useState(7)
    let [currentMsgAttachments, setCurrentMsgAttachments] = useState([])
    let [currentMsg, setCurrentMsg] = useState({})
    const attachmentContainerRef = useRef(null);
    let [cacheStarted] = useState(false)
    const headerContainerRef = useRef(null)
    const mailActionsListRef = useRef(null)
    let [searchValue, setSearchValue] = useState("")
    let [threadShowHideRecord, setThreadShowHideRecord] = useState({})
    let [threadData, setThreadData] = useState([])
    let [isSingleMessageRender, setIsSingleMessageRender] = useState(false)
    let searchButtonRef = useRef()
    let [refreshBannerShown, setIsRefreshBannerShown] = useState(false)

    async function startCache() {
        let mailboxes = StateManager.cacheMailData().boxes
        await initMailsCacheDB(mailboxes)
        if (StateManager.cacheMailData().device.IsSetupComplete) {
            console.log(`Setup is completed, handling socket now.`)
            handleSocket()
            return
        }
        let body = CACHE_CONF
        for (let i = 0; i < mailboxes.length; i++) {
            let lastId = 0
            body.box = mailboxes[i].name
            let currentMailboxDone = false
            while (!currentMailboxDone) {
                try {
                    body.lastId = lastId
                    let mailData = await listingForCache(body, StateManager.getToken())
                    if (_.isEmpty(mailData.mails)) {
                        currentMailboxDone = true
                        continue
                    }
                    let mails = mailData.mails
                    for (let j = 0; j < mails.length; j++) {
                        let mailObject = mails[j]
                        await parseAndAddEmailInCache(mailboxes[i].name, mailObject)
                        if (j === mails.length - 1) {
                            lastId = mails[j].uid
                        }
                    }
                    if (mails.length < CACHE_CONF.setSize) {
                        currentMailboxDone = true
                    }
                } catch (e) {
                    currentMailboxDone = true
                }
            }
            console.log(`Cache done for ${mailboxes[i].name}`)
        }
        let cacheData = _.cloneDeep(StateManager.cacheMailData())
        cacheData.device.IsSetupComplete = true
        StateManager.setCacheMailData({})
        StateManager.setCacheMailData(cacheData)
        console.log(`All Done, for cache`)
        handleSocket()
    }

    async function handleSocket() {
        if (StateManager.cacheMailData().device.IsSetupComplete) {
            let socket = initializeSocket(StateManager.getUserEmail(), StateManager.getToken(), getDeviceId())
            socket.on("message", (message) => {
                console.log(`Received push from backend.`)
                message = JSON.parse(message)
                updateCache(message)

                if (message.eventType === "newMessage" && !refreshBannerShown) {
                    refreshBannerShown = true
                    setIsRefreshBannerShown(refreshBannerShown)
                }
            })
        }
    }

    function addMessageShowInThreadShowHide(uid) {
        threadShowHideRecord[uid] = true
        setThreadShowHideRecord(threadShowHideRecord)
    }

    function addMessageHideInThreadShowHide(uid) {
        threadShowHideRecord[uid] = false
        setThreadShowHideRecord(threadShowHideRecord)
    }

    function stateUpdateCurrentMsg(data) {
        currentMsg = data
        setCurrentMsg(currentMsg)
    }


    function stateUpdateCurrentMessageAttachments(atc) {
        currentMsgAttachments = atc
        setCurrentMsgAttachments(currentMsgAttachments)
    }

    function stateUpdateHasNextPage(value) {
        hasNextPage = value
        setHasNextPage(hasNextPage)
    }

    function stateUpdateHasPreviousPage(value) {
        hasPreviousPage = value
        setHasPreviousPage(hasPreviousPage)
    }

    function nextPage() {
        currentPage = currentPage + 1
        setCurrentPage(currentPage)
    }

    function previousPage() {
        currentPage = currentPage - 1
        setCurrentPage(currentPage)
    }

    function stateUpdateCurrentPage(value) {
        currentPage = value;
        setCurrentPage(currentPage);
    }

    function stateUpdateTotalPages(value) {
        totalPages = value
        setTotalPages(totalPages)
    }

    function stateUpdateSortOption(event) {
        const value = event.target.value
        StateManager.setMailSortingKey(value)
        sortKey = value
        setSortKey(sortKey)
    }

    function stateUpdateSortOrder(event) {
        const value = event.target.value
        StateManager.setMailSortingOrder(value)
        sortOrder = value
        setSortOrder(sortOrder)
    }

    function stateUpdateMailData(data) {
        mailData = data
        setMailData(data);
    }

    function stateUpdateCurrentMailbox(value) {
        currentMbox = value
        setCurrentMailbox(currentMbox)
    }

    function stateUpdateMailBoxes(value) {
        mailboxes = value
        setMailboxes(mailboxes)
    }

    async function startingOperations() {
        if (!await userIsLoggedIn()) {
            redirect("/login");
        }
        const {boxes} = StateManager.cacheMailData()
        stateUpdateMailBoxes(boxes)
        await renderListing()
    }

    const renderListing = async () => {
        let mailData = []
        if (StateManager.getIsSearchRecordRender()) {
            console.log(StateManager.getSearchPlainText())
            mailData = await getSearchPaginatedResults(currentMbox, StateManager.getSearchEncryptedWords(), StateManager.getSearchCurrentPage(), pageSize)
            handleSearchValue(StateManager.getSearchPlainText())
            searchButtonRef.current.innerText = "Clear"
        } else {
            mailData = await listing(StateManager.getToken(), {
                box: currentMbox,
                pageSize: pageSize,
                pageNumber: currentPage,
                sortOption: StateManager.getMailSortingKey(),
                ascending: StateManager.getMailSortingOrder() === "ascending",
            });
        }

        if (!_.isEmpty(mailData.mails)) {
            const mails = await decryptSubjects(mailData.mails);
            stateUpdateMailData(mails);
            stateUpdateTotalPages(mailData.totalPages);
            stateUpdateCurrentPage(mailData.pageNumber);
            stateUpdateHasNextPage(mailData.hasNext);
            stateUpdateHasPreviousPage(mailData.hasPrevious);
        } else {
            stateUpdateMailData([]);
        }

        startCache()
    }

    useEffect(() => {
        toast.promise(startingOperations(), {
            pending: "data is loading",
            error: "server error occurred"
        }, {containerId: TOAST_CONTAINER_ID}).catch((e) => {
            console.error(e)
        })
    }, [currentMbox, currentPage, sortKey, sortOrder]);


    function handleSearchValue(value) {
        searchValue = value
        setSearchValue(searchValue)
    }

    async function stateUpdateSearch() {
        if (StateManager.getIsSearchRecordRender()) {
            StateManager.setIsSearchRecordRenderInState(false)
            StateManager.setSearchPlainTextInState("")
            StateManager.setSearchEncryptedWordsInState([])
            StateManager.setSearchCurrentPageInState(1)
            reloadPage()
            searchButtonRef.current.innerText = "Search"
            return
        }
        searchButtonRef.current.innerText = "Clear"
        if (_.isEmpty(searchValue)) {
            return
        }
        let words = []
        if (searchValue.includes(" ")) {
            words = searchValue.split(" ")
        } else {
            words = [searchValue]
        }
        let encryptedWords = []
        words.forEach((word) => {
            encryptedWords.push(md5(word))
        })
        StateManager.setSearchPlainTextInState(searchValue)
        StateManager.setSearchEncryptedWordsInState(encryptedWords)
        StateManager.setIsSearchRecordRenderInState(true)
        StateManager.setSearchCurrentPageInState(1)
        await renderListing()
    }

    async function decryptSubjects(mails) {
        for (let i = 0; i < mails.length; i++) {
            mails[i].decryptedSubject = decodeIfMimeEncoded(await decryptMessageSubject(mails[i]))
            mails[i].formattedDate = formatDateForMailDisplay(mails[i].date)
        }
        return mails
    }

    async function renderMessage(key) {
        try {
            toast.loading("Rendering message", {containerId: TOAST_CONTAINER_ID})
            markMessageAsRead(key)
            let mail = mailData[key]
            // let messageData = await readMessageByUid(currentMbox, mail.uid)
            // if (_.isEmpty(messageData)) {
            //     messageData = await fetchMessageBody(mail.uid, currentMbox, StateManager.getToken())
            // }
            let messageData = await fetchMessageBody(mail.uid, currentMbox, StateManager.getToken())

            if (messageData.IsThread) {
                return renderThread(messageData.uid)
            }

            isSingleMessageRender = true
            setIsSingleMessageRender(isSingleMessageRender)

            let [body, attachmentNames] = await Promise.all([
                decryptMessageBody(messageData),
                decryptMessageAttachmentNames(messageData)
            ])

            if (!body.startsWith(HTML_MESSAGE_IDENTIFIER)) {
                body = `<p>${body}</p>`
            } else {
                let html = body.split(HTML_MESSAGE_IDENTIFIER)[1]
                body = base64ToUtf8(html)
            }
            mail.formattedDate = formatDateForMailDisplay(mail.date)
            mail.decryptedBody = body
            mail.decryptedAttachmentNames = attachmentNames

            let attachments = attachmentNames.split("/")
            let newAttachments = []

            if (!_.isEmpty(attachmentNames)) {
                newAttachments = attachments.filter(item => item !== "")
            }

            mail.parsedAttachmentNames = newAttachments

            stateUpdateCurrentMessageAttachments(newAttachments)

            stateUpdateCurrentMsg(mail)

            injectHtmlInMessageIframe(body)

            displayHeadersAndActions()
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
        } catch (e) {
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.error(`Error rendering message`)
        }
    }

    function injectHtmlInMessageIframe(html, styles = "", cssFiles = []) {
        let iframe = document.getElementById("render-frame");
        let doc = iframe.contentWindow.document;
        doc.open();
        doc.write(html)
        cssFiles.forEach((fileLink) => {
            const fontAwesomeLink = doc.createElement("link");
            fontAwesomeLink.rel = "stylesheet";
            fontAwesomeLink.href = fileLink;
            doc.head.appendChild(fontAwesomeLink);
        })

        if (!_.isEmpty(styles)) {
            const styleElement = doc.createElement("style");
            styleElement.textContent = styles;
            doc.head.appendChild(styleElement);
        }

        doc.close()
    }

    function findElementFromMessageIframe(id) {
        let iframe = document.getElementById("render-frame")
        let doc = iframe.contentWindow.document
        return doc.getElementById(id)
    }

    async function renderThread(uid) {
        let threads = await fetchThread(StateManager.getToken(), {uid, box: currentMbox})
        let html = ""
        const iframeStyles = `
                    .message-action-icon-style {
                        font-size: 1rem;
                        display: block;
                        margin-bottom: 8px
                    }
                    .icon-item:hover {
                        background-color: #0070f3;
                    }
                    .icon-item {
                        text-align: center;
                        flex: 1;
                        cursor: pointer
                    }
                    .another-class {
                        background-color: lightgray;
                        padding: 10px;
                        border-radius: 5px;
                    }`;
        for (let i = 0; i < threads.length; i++) {
            let [subject, body, attachmentNames] = await Promise.all([
                decryptMessageSubject(threads[i]),
                decryptMessageBody(threads[i]),
                decryptMessageAttachmentNames(threads[i])
            ])

            if (threads[i].isEncrypted) {
                if (!body.startsWith(HTML_MESSAGE_IDENTIFIER)) {
                    body = `<p>${body}</p>`
                } else {
                    let html = body.split(HTML_MESSAGE_IDENTIFIER)[1]
                    body = base64ToUtf8(html)
                }
            } else {
                if (!threads[i].isHtml) {
                    body = `<p>${body}</p>`
                }
            }

            threads[i].formattedDate = formatDateForMailDisplay(threads[i].date)
            threads[i].decryptedBody = body
            threads[i].decryptedSubject = subject
            threads[i].decryptedAttachmentNames = attachmentNames
            console.log(`Thread: ${i} body: ${threads[i].body}`)
            console.log(`Thread: ${i} decrypted body: ${threads[i].decryptedBody}`)
            let attachments = threads[i].decryptedAttachmentNames.split("/")
            let newAttachments = []
            if (!_.isEmpty(attachmentNames)) {
                newAttachments = attachments.filter(item => item !== "")
            }
            threads[i].parsedAttachmentNames = newAttachments


            addMessageHideInThreadShowHide(threads[i].messageId)
            html += `<div id="${threads[i].messageId + '-header'}" style="width: 100%; border: 1px solid grey; height: 30px;">
                    <span><strong>On:</strong></span>&nbsp<span>${threads[i].formattedDate}</span><span style="margin-right: 5px; float: right">${i + 1}</span><br><br>
                    </div>
                    <br>
                    <div id="${threads[i].messageId + '-main'}" style="display: none; visibility: hidden; justify-content: space-between; margin: 0 auto">
                        <div style="display: grid; grid-auto-flow: column; gap: 10px;">
                            <div id="${threads[i].messageId}-reply" class="icon-item">
                                <i class="fas fa-reply message-action-icon-style"></i>
                                <p>Reply</p>
                            </div>
                            <div id="${threads[i].messageId}-replyAll" class="icon-item">
                                <i class="fas fa-reply-all message-action-icon-style"></i>
                                <p>Reply All</p>
                            </div>
                            <div id="${threads[i].messageId}-forward" class="icon-item">
                                <i class="fas fa-share message-action-icon-style"></i>
                                <p>Forward</p>
                            </div>
                            <div id="${threads[i].messageId}-delete" class="icon-item">
                                    <i class="fas fa-trash .message-action-icon-style"></i>`


            if (currentMbox === "Trash") {
                html += `<p>Delete This Message</p>`
            } else {
                html += `<p>Trash This Message</p>`
            }
            html += `</div>`

            html += `<div id="${threads[0].messageId}-full-thread-delete" class="icon-item">
                                    <i class="fas fa-trash .message-action-icon-style"></i>`


            if (currentMbox === "Trash") {
                html += `<p>Delete Whole Thread</p>`
            } else {
                html += `<p>Trash Whole Thread</p>`
            }
            html += `</div>`
            html += `</div><br>`
            html += `<div style="width: 100%"><span><strong>From:</strong></span>&nbsp<span>${parseContactTypeEmail(threads[i].from)}</span><br>
                        <span><strong>To:</strong></span>&nbsp<span>${parseContactTypeEmail(threads[i].to)}</span><br>
                        <span><strong>CC:</strong></span>&nbsp<span>${parseContactTypeEmail(threads[i].cc)}</span><br>
                        <span><strong>Date:</strong></span>&nbsp<span>${threads[i].formattedDate}</span><br>
                        <span><strong>Subject:</strong></span>&nbsp<span>${threads[i].decryptedSubject}</span></div><br>`

            html += prepareAttachmentsHtml(threads[i])
            html += `<br><br>`
            html += threads[i].decryptedBody
            html += `</div>`
        }

        injectHtmlInMessageIframe(html, iframeStyles, ["https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"])
        addEventsForThreadRenderInIframe(threads)

        threadData = threads
        setThreadData(threadData)
        toast.dismiss({containerId: TOAST_CONTAINER_ID})
    }

    function countThreadSize(thread) {
        if (thread.references.includes(",")) {
            let total = thread.references.split(",")
            return total.length + 1
        } else {
            return 2
        }
    }

    function addEventsForThreadRenderInIframe(threads) {
        let deleteWholeThreadButton = findElementFromMessageIframe(`${threads[0].messageId}-full-thread-delete`)

        deleteWholeThreadButton.addEventListener("click", () => {
            deleteFullThread(threads[0].uid)
        })

        threads.forEach((thread) => {
            let header = findElementFromMessageIframe(`${thread.messageId}-header`)
            header.onclick = function () {
                showHideThreadMainMessage(thread.messageId)
            }
            addEventsForAttachmentsDownload(thread, true)
            findElementFromMessageIframe(`${thread.messageId}-reply`).onclick = () => {
                replyThread(thread)
            }
            findElementFromMessageIframe(`${thread.messageId}-replyAll`).onclick = () => {
                replyAllThread(thread)
            }
            findElementFromMessageIframe(`${thread.messageId}-forward`).onclick = () => {
                forwardThread(thread)
            }
            findElementFromMessageIframe(`${thread.messageId}-delete`).onclick = () => {
                if (currentMbox === "Trash") {
                    deleteMsgThread(thread)
                } else {
                    moveMsgToTrashThread(thread)
                }
            }
        })
    }

    function showHideThreadMainMessage(uid) {
        let element = findElementFromMessageIframe(`${uid}-main`)
        if (threadShowHideRecord[uid]) {
            element.style.display = "none"
            element.style.visibility = "hidden";
            addMessageHideInThreadShowHide(uid)
        } else {
            element.style.display = "block"
            element.style.visibility = "visible";
            addMessageShowInThreadShowHide(uid)
        }

    }


    async function downloadAttachment(attachmentName, box, uid, isEncrypted = true) {
        let attachmentData = await fetchAttachmentData(StateManager.getToken(), {
            attachmentName,
            box,
            uid,
            isEncrypted
        })
        let decrypted = ""
        if (isEncrypted) {
            decrypted = await decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), attachmentData.attachmentData)
            await downloadEncryptedAttachment(decrypted, attachmentName)
        } else {
            await downloadPlainAttachment(attachmentData.plainAttachmentData, attachmentName)
        }
    }

    const downloadEncryptedAttachment = (attachmentData, attachmentName) => {
        let [attachmentHeaders, attachmentBase64] = attachmentData.split("\n\n")
        let headerParts = attachmentHeaders.split("\n")
        let contentTypePart = headerParts[1]
        let contentTypeParts = contentTypePart.split(":")
        let contentType = contentTypeParts[1].split(";")[0]
        let byteArray = base64ToByteArray(attachmentBase64)
        let file = createFile([byteArray], contentType)
        downloadFile(attachmentName, file)
    }

    const downloadPlainAttachment = (attachmentData, attachmentName) => {
        let attachmentHeaders = attachmentData.headers
        let attachmentBase64 = attachmentData.data
        let contentTypePart = attachmentHeaders["Content-Type"]
        let contentType = contentTypePart.split(";")[0]
        let byteArray = base64ToByteArray(attachmentBase64)
        let file = createFile([byteArray], contentType)
        downloadFile(attachmentName, file)
    }

    const prepareAttachmentsHtml = (message) => {
        if (_.isEmpty(message)) {
            return ``
        }
        let html = ``
        message.parsedAttachmentNames.forEach((attachment) => {
            html += `<div id="${message.messageId}-atc-${md5(attachment)}" style="flex: 1 0 150px; background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; box-sizing: border-box;">
            ${attachment}
            </div>`
        })
        return html
    }

    const addEventsForAttachmentsDownload = (message, iframe = false) => {
        if (_.isEmpty(message)) {
            return ``
        }
        message.parsedAttachmentNames.forEach((attachment) => {
            let atc
            let id = `${message.messageId}-atc-${md5(attachment)}`
            if (iframe) {
                atc = findElementFromMessageIframe(id)
            } else {
                atc = document.getElementById(id)
            }
            atc.onclick = () => downloadAttachment(attachment, message.box, message.uid, message.isEncrypted)
        })
    }

    useEffect(() => {
        attachmentContainerRef.current.innerHTML = prepareAttachmentsHtml(currentMsg)
        addEventsForAttachmentsDownload(currentMsg)
    }, [currentMsg]);


    function displayHeadersAndActions() {
        headerContainerRef.current.style.display = "block"
        mailActionsListRef.current.style.display = "flex"
    }

    async function deleteFullThread(threadMainMessageUid) {
        let id = toast.loading("deleting full thread", {containerId: TOAST_CONTAINER_ID})
        await deleteFullThreadOnServer(StateManager.getToken(), {uid: threadMainMessageUid, box: currentMbox})
        toast.dismiss(id)
        toast.success("Thread deleted", {containerId: TOAST_CONTAINER_ID})
        reloadPage()
    }

    function replyThread(thread) {
        StateManager.setRenderedMailData(thread)
        redirect("/compose?isReply=true");
    }

    function replyAllThread(thread) {
        StateManager.setRenderedMailData(thread)
        redirect("/compose?isReplyAll=true");
    }

    function forwardThread(thread) {
        StateManager.setRenderedMailData(thread)
        redirect("/compose?isForward=true");
    }

    async function deleteMsgThread(thread) {
        StateManager.setRenderedMailData(thread)
        try {
            toast.loading("Deleting message", {containerId: TOAST_CONTAINER_ID})
            await deleteMessage(StateManager.getToken(), {uid: thread.uid, box: thread.box})
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.success("Message delete success")
        } catch (e) {
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.error(e.message)
        }
    }

    async function moveMsgToTrashThread(thread) {
        StateManager.setRenderedMailData(thread)
        try {
            toast.loading("moving message", {containerId: TOAST_CONTAINER_ID})
            await moveMessageOnServer(StateManager.getToken(), {uid: thread.uid, to: "Trash", from: thread.box})
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.success("Message move success")
        } catch (e) {
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.error(e.message)
        }
    }

    function reply() {
        StateManager.setRenderedMailData(currentMsg)
        redirect("/compose?isReply=true");
    }

    function replyAll() {
        StateManager.setRenderedMailData(currentMsg)
        redirect("/compose?isReplyAll=true");
    }

    function forward() {
        StateManager.setRenderedMailData(currentMsg)
        redirect("/compose?isForward=true");
    }

    async function deleteMsg() {
        try {
            toast.loading("Deleting message", {containerId: TOAST_CONTAINER_ID})
            await deleteMessage(StateManager.getToken(), {uid: currentMsg.uid, box: currentMbox})
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.success("Message delete success")
        } catch (e) {
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.error(e.message)
        }
    }

    async function moveMsgToTrash() {
        try {
            toast.loading("moving message", {containerId: TOAST_CONTAINER_ID})
            await moveMessageOnServer(StateManager.getToken(), {uid: currentMsg.uid, to: "Trash", from: currentMbox})
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.success("Message move success")
        } catch (e) {
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.error(e.message)
        }
    }

    async function markMessageAsRead(key) {
        let recentFlagIndex = mailData[key].flags.indexOf("Recent")
        if (recentFlagIndex > -1) {
            mailData[key].flags[recentFlagIndex] = "Seen"
            setMailData(mailData)
            await markMessageAsReadBackend(StateManager.getToken(), {uid: mailData[key].uid, box: currentMbox})
        }
    }

    return (
        <>
            <Helmet>
                <title>Mail Page</title>
            </Helmet>
            <Header></Header>
            <div className="container-fluid" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0" style={{border: "1px solid grey"}}>
                        <ul style={{listStyleType: 'none'}} className="p-0">
                            {mailboxes.map((element) => {

                                const isHighlighted = element.name === currentMbox;

                                if (!isHighlighted) {

                                    return (
                                        <li key={element.name} id={element.name} className="customLi mb-2 p-1"
                                            style={{cursor: "pointer"}}
                                            onClick={() => stateUpdateCurrentMailbox(element.name)}>
                                            {createSubstring(element.name, 10)}
                                        </li>)
                                } else {
                                    return (
                                        <li key={element.name} id={element.name} className="highlighted mb-2 p-1"
                                            style={{cursor: "pointer"}}
                                            onClick={() => stateUpdateCurrentMailbox(element.name)}>
                                            {createSubstring(element.name, 10)}
                                        </li>)
                                }

                            })}
                        </ul>
                    </div>

                    <div className="col-md-3 col-lg-3 col-xl-3 p-0" style={{border: "1px solid grey"}}>
                        <StandardTextInput value={searchValue} onChange={handleSearchValue} disabled={cacheStarted}
                                           styles={{height: "2vh", width: "20vh"}}
                                           placeholder="Search here">
                        </StandardTextInput>&nbsp;
                        <button className={Styles.StandardButton} ref={searchButtonRef}
                                style={{paddingTop: 0, paddingBottom: 0}}
                                onClick={stateUpdateSearch}>Search
                        </button>
                        <br></br>
                        <StandardDropdown onChange={stateUpdateSortOption}
                                          selectedValue={StateManager.getMailSortingKey()} name="Sort Option"
                                          options={sortOptions}></StandardDropdown>&nbsp;
                        <StandardDropdown onChange={stateUpdateSortOrder}
                                          selectedValue={StateManager.getMailSortingOrder()} name="Sort Order"
                                          options={sortOrders}></StandardDropdown><br></br><br></br>
                        <div>Showing
                            Page <span>{currentPage}</span> of <span>{totalPages}  </span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={previousPage} disabled={!hasPreviousPage}>&lt;Page</button>
                            &nbsp;
                            <button onClick={nextPage} disabled={!hasNextPage}>Page&gt;</button>
                        </div>
                        <br></br>
                        {refreshBannerShown && (
                            <div style={{
                                height: "20px",
                                width: "100%",
                                backgroundColor: "yellow",
                                marginBottom: "5px"
                            }}>
                                <span>New mail received. <a onClick={reloadPage} type="button"><strong>Refresh</strong></a></span>
                            </div>)}
                        <ul id="mail-list" className="p-0" style={{listStyle: "none", width: "100%"}}>
                            {mailData.map((mailItem, key) => (
                                <li key={mailItem.uid} id={mailItem.uid} onClick={() => renderMessage(key)}
                                    className="mb-2 mailListLi">
                                    <div className={Styles.mailListItem}
                                         style={{display: "flex", alignItems: "center"}}>
                                        {/* Yellow Dot for Unread */}
                                        {mailItem.flags && mailItem.flags.includes("Recent") && (
                                            <span style={{
                                                height: "8px",
                                                width: "8px",
                                                backgroundColor: "#FF8C00",
                                                borderRadius: "50%",
                                                display: "inline-block",
                                                marginRight: "8px"
                                            }}></span>
                                        )}

                                        {/* Email details */}
                                        <div>
                                    <span>
                                        <strong>Subject:</strong> {createSubstring(mailItem.decryptedSubject, 10)}
                                            </span>
                                            <br/>
                                            <span><strong>From:</strong> {createSubstring(decodeIfMimeEncoded(mailItem.from), 10)}</span>
                                            <br/>
                                            <span><strong>On:</strong> {mailItem.formattedDate}</span>
                                        </div>

                                        {/* Reply Icon and Thread Size */}
                                        <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                                            {mailItem.IsThread && (
                                                <>
                                                    {/* Font Awesome Reply Icon */}
                                                    <span style={{marginRight: "8px"}}>
                                <i className="fas fa-reply fa-xs" aria-hidden="true"></i>
                            </span>

                                                    {/* Thread Size */}
                                                    <span style={{fontSize: "12px", color: "grey"}}>
                                ({countThreadSize(mailItem)})
                            </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                            {mailData.length === 0 && (<span>Nothing to show</span>)}
                        </ul>
                    </div>

                    {/* Email Content Section */}

                    <div style={{}}
                         className="col-md-7 col-lg-7 col-xl-7">
                        <br></br>
                        <div ref={mailActionsListRef}
                             style={{display: "none", justifyContent: 'space-between', margin: '0 auto'}}
                             className="icon-list">
                            <div onClick={reply} className={Styles.iconItem}>
                                <i className="fas fa-reply"></i>
                                <p>Reply</p>
                            </div>
                            <div onClick={replyAll} className={Styles.iconItem}>
                                <i className="fas fa-reply-all"></i>
                                <p>Reply All</p>
                            </div>
                            <div onClick={forward} className={Styles.iconItem}>
                                <i className="fas fa-share"></i>
                                <p>Forward</p>
                            </div>
                            {currentMbox !== "Trash" && (
                                <div onClick={moveMsgToTrash} className={Styles.iconItem}>
                                    <i className="fas fa-trash"></i>
                                    <p>Move to Trash</p>
                                </div>)
                            }
                            {currentMbox === "Trash" && (
                                <div onClick={deleteMsg} className={Styles.iconItem}>
                                    <i className="fas fa-cross"></i>
                                    <p>Delete</p>
                                </div>)}
                        </div>

                        <div ref={headerContainerRef} style={{display: "none", width: "100%"}}
                             className="message-render-headers-section">
                            <span><strong>From</strong>: {decodeIfMimeEncoded(currentMsg.from)} </span><br></br>
                            <span><strong>To</strong>: {currentMsg.to} </span><br></br>
                            <span><strong>CC:</strong> {currentMsg.cc} </span><br></br>
                            <span><strong>On:</strong> {currentMsg.formattedDate} </span><br></br>
                            <span><strong>Subject:</strong> {currentMsg.decryptedSubject} </span><br></br>

                        </div>
                        <br></br>
                        <div id="attachment-names"
                             ref={attachmentContainerRef}
                             style={{display: "flex", flexWrap: "wrap", gap: "5px", width: "100%"}}
                             className="attachment-names">
                        </div>
                        <br></br>
                        <iframe style={{
                            width: "100%",
                            display: "block",
                            height: "90%",
                            border: "none",
                        }}
                                id="render-frame">

                        </iframe>
                    </div>
                </div>
            </div>
        </>
    );
}