import {useEffect, useRef, useState} from "react";
import {userIsLoggedIn} from "../utils/utils";
import {useNavigation} from "../hooks/navigation"
import StateManager from "../redux/StateManager";
import styles from "../styles/main.module.css";
import StandardButton from "../components/basic/StandardButton";
import {
    findChangeOwnershipRequest,
    findMainAccountByExtAccount,
    getMessagesCount,
    listingForBoxMove,
    listingForCache, sendLoginRequest, startChangeOwnershipProgress
} from "../server-calls";
import _ from "lodash"
import {useLocation} from "react-router-dom";
import {initializeSocket} from "../socket";
import {ConfirmationModal} from "../components/basic/ConfirmationModal";
import {render} from "@testing-library/react";
import StandardTextInput from "../components/basic/StandardTextInput";
import StandardPasswordInput from "../components/basic/StandardPasswordInput";
import {generateSha512Hash} from "../business/hashing";
import {getDeviceId} from "../cookie";
import {LOGIN_TYPES, TOAST_CONTAINER_ID} from "../utils/constants";
import {toast, ToastContainer} from "react-toastify";

export default function ExternalUser() {
    const {redirect} = useNavigation();
    const location = useLocation()

    useEffect(() => {
        startingOperations()
    }, []);

    let [newUserEmail, setNewUserEmail] = useState("")
    let [progressStarted, setProgressStarted] = useState(false)
    let [privateKey, setPrivateKey] = useState("")
    let [passphrase, setPassphrase] = useState("")
    let [token, setToken] = useState("")
    let [finished, setFinished] = useState(false)
    let [fromUserEmail, setFromUserEmail] = useState("")
    let [currentMbox, setCurrentMbox] = useState("INBOX")
    let [totalMessages, setTotalMessages] = useState(0)
    let [totalChanged, setTotalChanged] = useState(0)
    let [justLanded, setJustLanded] = useState(false)

    async function startingOperations() {
        if (!_.isEmpty(location.state) && !location.state.afterAccountConfirm) {
            console.log("landed")
            StateManager.cleanState()
            setFromUserEmail(location.state.extEmail)
            setJustLanded(true)
        } else if (!_.isEmpty(location.state) && location.state.afterAccountConfirm) {
            console.log("after account confirmation")
            setFromUserEmail(location.state.extEmail)
            setNewUserEmail(location.state.newUserEmail)
            setJustLanded(false)
        }
    }

    async function startMovingEmails() {
        toast.loading("Starting progress", {containerId: TOAST_CONTAINER_ID})
        let accessToken = generateSha512Hash(passphrase)

        try {
            let resp = await sendLoginRequest({
                email: fromUserEmail,
                accessToken,
                loginType: LOGIN_TYPES.emailPass.value,
                deviceId: getDeviceId()
            })

            setPrivateKey(resp.privateKey)
            setToken(resp.token)

            handleSocket(fromUserEmail, resp.token, getDeviceId())

            let existingProgress = await findChangeOwnershipRequest({
                userEmail: fromUserEmail,
                newUserEmail: newUserEmail
            }, resp.token)

            if (!_.isEmpty(existingProgress)) {
                setTotalMessages(existingProgress.totalMessages)
                setTotalChanged(existingProgress.totalChanged)
                setCurrentMbox(existingProgress.currentMbox)
                setProgressStarted(true)
            } else {
                await startChangeOwnershipProgress({
                    userEmail: fromUserEmail,
                    newUserEmail: newUserEmail,
                    userPrivateKey: resp.privateKey,
                    userPassphrase: passphrase
                }, resp.token)
            }
            setProgressStarted(true)
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
        } catch (e) {
            toast.dismiss()
            toast.error("Wrong passphrase please try again.", {containerId: TOAST_CONTAINER_ID})
        }
    }

    function handleSocket(userEmail, token, deviceId) {
        if (StateManager.cacheMailData().device.IsSetupComplete) {
            let socket = initializeSocket(userEmail, token, deviceId)
            socket.on("message", (message) => {
                message = JSON.parse(message)
                console.log(message)
                if (message.eventType === "messageOwnershipChanged") {
                    if (!_.isEmpty(message.finished)) {
                        setFinished(true)
                    } else {
                        setTotalMessages(message.totalMessages)
                        setTotalChanged(message.totalChanged)
                    }
                }
            })
        }
    }

    async function redirectToRegister() {
        redirect("/register", {ext: true, extEmail: fromUserEmail})
    }

    async function redirectToLogin() {
        redirect("/login", {ext: true, extEmail: fromUserEmail})
    }

    function plainRedirectToLogin() {
        redirect("/login")
    }

    return (
        <>
            <div className={styles.container}>
                <br></br><br></br>
                {(justLanded && !progressStarted) && (<>
                    <span>Register an Account to Access your Encrypted Emails</span><br></br>
                    <span>Already have an account and want to merge ?</span><br></br>
                    <StandardButton innerText={"Proceed with Account Connect"}
                                    onClick={redirectToLogin}/><br></br>
                    <label>Or</label><br></br>
                    <StandardButton onClick={redirectToRegister} innerText={"Register Here"}/><br></br>
                </>)}

                {(!justLanded && !progressStarted) && (<>
                    <span>Ready to Connect your external account to <strong>{newUserEmail}</strong></span><br></br>
                    <label>External Email Address</label><br></br>
                    <StandardTextInput value={fromUserEmail} isDisabled={true}/><br></br>
                    <label>Passphrase you Received in Email Notification</label><br></br>
                    <StandardPasswordInput placeholder={"Passphrase"} onChange={setPassphrase}/><br></br>
                    <StandardButton innerText={"Start Moving Progress"} onClick={startMovingEmails}/>
                </>)}

                {(progressStarted && !finished) && (
                    <span>Moving {currentMbox} - {totalChanged}/{totalMessages} moved</span>
                )}

                {(finished) && (<>
                        <span>All Done, you can continue to login page.</span><br></br>
                        <StandardButton innerText={"To Login"} onClick={plainRedirectToLogin}/>
                    </>
                )}

                <ToastContainer containerId={TOAST_CONTAINER_ID}/>
            </div>
        </>
    );
}