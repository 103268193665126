const BASE_URL = "https://api.privmail.com:2100"
const endPoints = {
    socketUrl: "wss://socket.privmail.com",
    register: {url: `${BASE_URL}/v1/user/register`, method: "POST"},
    login: {url: `${BASE_URL}/v1/user/login`, method: "POST"},
    checkUsernameDuplication: {url: `${BASE_URL}/v1/user/check-duplicate-user`, method: "POST"},
    checkSession: {url: `${BASE_URL}/v1/user/session-status`, method: "GET"},
    logout: {url: `${BASE_URL}/v1/user/logout`, method: "GET"},
    emailListing: {url: `${BASE_URL}/v1/mail/listing`, method: "POST"},
    boxesListing: {url: `${BASE_URL}/v1/mail/list-boxes`, method: "GET"},
    fetchReceiversPublicKeys: {url: `${BASE_URL}/v1/user/fetch-public-keys`, method: "POST"},
    sendMessageHttp: {url: `${BASE_URL}/v1/mail/send`, method: "POST"},
    listingForCache: {url: `${BASE_URL}/v1/mail/listing-for-cache`, method: "POST"},
    listingForBoxMove: {url: `${BASE_URL}/v1/mail/listing-for-box-move`, method: "POST"},
    fetchMessageBodyForRender: {url: `${BASE_URL}/v1/mail/fetch-body`, method: "POST"},
    fetchAttachmentData: {url: `${BASE_URL}/v1/mail/fetch-attachment-data`, method: "POST"},
    getMessagesCount: {url: `${BASE_URL}/v1/mail/get-messages-count`, method: "GET"},
    markMessageAsRead: {url: `${BASE_URL}/v1/mail/mark-message-as-read`, method: "POST"},
    fetchThread: {url: `${BASE_URL}/v1/mail/fetch-thread`, method: "POST"},
    markDeviceAsComplete: {url: `${BASE_URL}/v1/device/mark-as-complete`, method: "POST"},
    moveMessage: {url: `${BASE_URL}/v1/mail/move-message`, method: "POST"},
    deleteMessage: {url: `${BASE_URL}/v1/mail/delete-message`, method: "POST"},
    deleteFullThread: {url: `${BASE_URL}/v1/mail/delete-full-thread`, method: "POST"},

    addDomain: {url: `${BASE_URL}/v1/domain/add-domain`, method: "POST"},
    updateDomain: {url: `${BASE_URL}/v1/domain/update-domain`, method: "POST"},
    deleteDomain: {url: `${BASE_URL}/v1/domain/delete-domain`, method: "POST"},
    fetchDomain: {url: `${BASE_URL}/v1/domain/get-domain`, method: "POST"},
    listDomains: {url: `${BASE_URL}/v1/domain/list-domains`, method: "POST"},
    findExternalReceivers: {url: `${BASE_URL}/v1/domain/find-external-emails`, method: "POST"},

    addAlias: {url: `${BASE_URL}/v1/user/add-alias`, method: "POST"},
    deleteAlias: {url: `${BASE_URL}/v1/user/delete-alias`, method: "POST"},
    listAliases: {url: `${BASE_URL}/v1/user/list-aliases`, method: "POST"},
    fetchUserAccounts: {url: `${BASE_URL}/v1/user/get-user-accounts`, method: "GET"},
    switchLogin: {url: `${BASE_URL}/v1/user/switch-login`, method: "POST"},

    registerExternalAccounts: {url: `${BASE_URL}/v1/user/register-external-users`, method: "POST"},

    existingGmailProgress: {url: `${BASE_URL}/v1/gmail/progress`, method: "GET"},
    startGmailSync: {url: `${BASE_URL}/v1/gmail/start-sync`, method: "POST"},
    resumeGmailSync: {url: `${BASE_URL}/v1/gmail/resume-sync`, method: "POST"},
    pauseGmailSync: {url: `${BASE_URL}/v1/gmail/pause-sync`, method: "POST"},
    cancelGmailSync: {url: `${BASE_URL}/v1/gmail/cancel-sync`, method: "POST"},


    findMainAccountByExtAccount: {url: `${BASE_URL}/v1/user/find-main-account-by-ext-account`, method: "POST"},
    findExistingChangeOwnershipRequest: {url: `${BASE_URL}/v1/mail/fetch-change-ownership-progress`, method: "POST"},
    startChangeOwnershipProgress: {url: `${BASE_URL}/v1/mail/change-ownership-of-account`, method: "POST"},

}

export function getEndpoints() {
    return endPoints
}