import Navigation from "../components/navigation/index"
import Header from "../components/header";
import {useEffect} from "react";
import {userIsLoggedIn} from "../utils/utils";
import {useNavigation} from "../hooks/navigation"
import StateManager from "../redux/StateManager";
import {useLocation} from "react-router-dom";
import _ from "lodash";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function Landing() {
    const {redirect} = useNavigation();
    const query = useQuery()
    useEffect(() => {
        checkUserLoggedIn()
    }, []);

    async function checkUserLoggedIn() {
        const isLoggedIn = await userIsLoggedIn();
        if (!isLoggedIn && _.isEmpty(query.get("ext")) && query.get("ext") !== "true") {
            redirect("/login");
        } else if (isLoggedIn && _.isEmpty(query.get("ext")) && query.get("ext") !== "true") {
            redirect("/mail")
        } else {
            let data = {ext: true, afterAccountConfirm: false, extEmail: query.get("extEmail")}
            redirect("/ext-user", data)
        }
    }

    return (
        <>
            <Header></Header>
            <div className="container-fluid" style={{height: '95vh'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                </div>
            </div>
        </>
    );
}