import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "../components/navigation";
import Header from "../components/header";
import {useEffect, useRef, useState} from "react";
import {convertFileToBase64, parseContactTypeEmail, userIsLoggedIn} from "../utils/utils";
import {encryptMessage} from "../mail/utils";
import StateManager from "../redux/StateManager"
import {
    fetchPublicKeys,
    findExternalReceivers,
    registerExternalUsers,
    sendMessageToBackend
} from "../server-calls/index";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer} from 'react-toastify';
import {useLocation} from "react-router-dom";
import {useNavigation} from "../hooks/navigation";
import {TOAST_CONTAINER_ID} from "../utils/constants";
import Modal from 'react-modal';
import StandardTextInput from "../components/basic/StandardTextInput";
import StandardButton from "../components/basic/StandardButton";

Modal.setAppElement('#root');

export default function Compose() {

    const passphraseModalStyles = {
        content: {
            width: "400px",
            height: "320px",
            margin: "auto",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    };


    const {redirect, reloadPage} = useNavigation()
    let [to, setTo] = useState("");
    let [cc, setCc] = useState("");
    let [bcc, setBcc] = useState("");
    let [passphrase, setPassphrase] = useState("");
    let [toList, setToList] = useState([]);
    let [ccList, setCcList] = useState([]);
    let [bccList, setBccList] = useState([]);
    let [messageBody, setMessageBody] = useState("");
    let [subject, setSubject] = useState("");
    let [isHtml, setIsHtml] = useState(false);
    let [isEncrypt, setIsEncrypt] = useState(true);
    let [attachments, setAttachments] = useState([]);
    let [isBusy, setIsBusy] = useState(false)
    let replyToRef = useRef(null)
    let subjectRef = useRef(null)
    let ccRef = useRef(null)
    let bccRef = useRef(null)
    let toRef = useRef(null)
    let mainBodyRef = useRef(null)
    let replyToParentRef = useRef(null)

    const location = useLocation();

    let [isReply, setIsReply] = useState(false);
    let [isReplyAll, setIsReplyAll] = useState(false)
    let [isForward, setIsForward] = useState(false)
    let [isPassphraseModalOpen, setIsPassphraseModalOpen] = useState(false)
    let [isPassphraseError, setIsPassphraseError] = useState(false)
    let [passphraseError, setPassphraseError] = useState("")
    let [externalEmails, setExternalEmails] = useState({to: [], cc: [], bcc: []})
    let [originalExternalEmails, setOriginalExternalEmails] = useState([])
    let [externalEncrypted, setExternalEncrypted] = useState(false)

    const handleExternalEncrypted = (value) => {
        externalEncrypted = value
        setExternalEncrypted(externalEncrypted)
    }

    const handleOriginalExternalEmailsInState = (value) => {
        originalExternalEmails = value
        setOriginalExternalEmails(originalExternalEmails)
    }

    const handleExternalEmailsInState = (to, cc, bcc) => {
        externalEmails.bcc = bcc
        externalEmails.to = to
        externalEmails.cc = cc
        setExternalEmails(externalEmails)
    }

    const handlePassphraseInState = (value) => {
        passphrase = value
        setPassphrase(passphrase)
    }

    const handleIsPassphraseErrorInState = (value) => {
        isPassphraseError = value
        setIsPassphraseError(isPassphraseError)
    }

    const handlePassphraseErrorInState = (value) => {
        passphraseError = value
        setPassphraseError(passphraseError)
    }

    const handleIsReplyState = (value) => {
        isReply = value
        setIsReply(isReply)
    }

    const handleIsReplyAllState = (value) => {
        isReplyAll = value
        setIsReplyAll(isReplyAll)
    }

    const handleIsForwardState = (value) => {
        isForward = value
        setIsForward(isForward)
    }

    let [referenceMessageData, setReferenceMessageData] = useState({});

    function handleReferenceMessageData(value) {
        referenceMessageData = value
        setReferenceMessageData(referenceMessageData);
    }

    useEffect(() => {
        userIsLoggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) {
                redirect("/login")
            }
        }).catch((error) => {
            console.error(error)
        })
        const queryParams = new URLSearchParams(location.search);
        handleIsReplyState(queryParams.get('isReply') === 'true')
        handleIsReplyAllState(queryParams.get('isReplyAll') === 'true')
        handleIsForwardState(queryParams.get('isForward') === 'true')
    }, [location.search]);


    useEffect(() => {
        if (isReply) {
            performReplyOperation()
        } else if (isReplyAll) {
            performReplyAllOperation()
        } else if (isForward) {
            performForwardOperation()
        }


    }, [isReply, isReplyAll, isForward, replyToRef]);

    const performReplyOperation = () => {
        handleReferenceMessageData(StateManager.renderedMailData())
        replyToRef.current.value = referenceMessageData.decryptedBody
        replyToRef.current.disabled = true
        replyToRef.current.style.overflowY = 'scroll'
        replyToRef.current.style.overflowX = 'scroll'
        replyToParentRef.current.style.visibility = 'visible'

        if (!referenceMessageData.decryptedSubject.startsWith("Re")) {
            handleOperationSubject(`Re: ${referenceMessageData.decryptedSubject}`)
        } else {
            handleOperationSubject(referenceMessageData.decryptedSubject)
        }

        handleToList(parseToEmails(referenceMessageData.from))
    }

    const performReplyAllOperation = () => {
        performReplyOperation()
        handleCcList(parseToEmails(referenceMessageData.cc))
    }

    const performForwardOperation = () => {
        handleReferenceMessageData(StateManager.renderedMailData())
        mainBodyRef.current.value = `${referenceMessageData.decryptedBody}`
        if (!referenceMessageData.decryptedSubject.startsWith("Fw")) {
            handleOperationSubject(`Fw: ${referenceMessageData.decryptedSubject}`)
        } else {
            handleOperationSubject(referenceMessageData.decryptedSubject)
        }
    }

    function handleOperationSubject(subject) {
        handleSubject(subject)
        subjectRef.current.value = subject
    }

    const parseToEmails = (to) => {
        let emails = []
        if (to.includes(",")) {
            to = to.replaceAll(" ", "")
            let parts = _.split(to, ",")
            parts.forEach((part) => {
                emails.push(parseContactTypeEmail(part))
            })
            return emails
        } else {
            return [parseContactTypeEmail(to)]
        }
    }


    const inputWrapperStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    };

    const tagStyle = {
        display: 'inline-block',
        background: '#e2e6ea',
        borderRadius: '15px',
        padding: '5px 10px',
        margin: '5px',
    };

    const removeButtonStyle = {
        marginLeft: '8px',
        background: 'transparent',
        border: 'none',
        color: '#dc3545',
        cursor: 'pointer'
    };

    const inputStyle = {
        border: 'none',
        outline: 'none',
        flexGrow: 1,
    };


    const handleToChange = (e) => {
        const value = e.target.value;
        setTo(value)
        if (value.includes(",") || value.includes(" ")) {
            let validEmails = handleEmailAddressChange(value)
            handleToList(validEmails)
            setTo("")
        }
    };

    const handleToList = (emails) => {
        emails.forEach((email) => {
            if (toList.indexOf(email) < 0) {
                toList.push(email)
            }
        })
        setToList(toList)
    }

    const handleCcChange = (e) => {
        const value = e.target.value;
        setCc(value);
        if (value.includes(",") || value.includes(" ")) {
            const validEmails = handleEmailAddressChange(value)
            handleCcList(validEmails)
            setCc("")
        }
    };

    const handleCcList = (emails) => {
        emails.forEach((email) => {
            if (ccList.indexOf(email) < 0) {
                ccList.push(email)
                setCcList(ccList)
            }
        })
    }

    const handleBccChange = (e) => {
        const value = e.target.value;
        setBcc(value);
        if (value.includes(",") || value.includes(" ")) {
            let validEmails = handleEmailAddressChange(value)
            handleBccList(validEmails)
            setBcc("")
        }
    };

    const handleBccList = (emails) => {
        emails.forEach((email) => {
            if (bccList.indexOf(email) < 0) {
                bccList.push(email)
                setBccList(bccList)
            }
        })
    }

    const handleEmailAddressChange = (value) => {
        if (value.includes(',') || value.includes(" ")) {
            const newEmails = value.split(',').map(email => email.trim()).filter(email => email !== '');
            return newEmails.filter(validateEmail);
        }
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleRemoveEmail = (list, setList, index) => {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList)
    };

    const handleIsBusy = (value) => {
        isBusy = value
        setIsBusy(isBusy)
    }

    const handleFileUpload = async (e) => {
        let files = Array.from(e.target.files)
        let built = []
        for (let i = 0; i < files.length; i++) {
            let type = files[i].type
            if (_.isEmpty(type))
                type = "text/plain"
            built.push({
                name: files[i].name,
                content: await convertFileToBase64(files[i]),
                type
            })
        }
        attachments = built
        setAttachments(attachments);
    };

    const handleEncryptChange = () => {
        setIsEncrypt(!isEncrypt);
    };

    const handleSubject = (value) => {
        subject = value
        setSubject(subject)
    }

    const handleBody = (value) => {
        messageBody = value
        setMessageBody(messageBody)
    }

    const handlePassphraseModalOpenClose = () => {
        isPassphraseModalOpen = !isPassphraseModalOpen;
        setIsPassphraseModalOpen(isPassphraseModalOpen)
    }


    const sendMessage = async () => {
        if (_.isEmpty(toList)) {
            toast.error(`please enter addresses properly`, {containerId: TOAST_CONTAINER_ID})
            return
        }

        if (isEncrypt) {
            toast.loading("checking external accounts", {containerId: TOAST_CONTAINER_ID})
            let body = {}
            if (!_.isEmpty(toList)) {
                body.to = toList
            }

            if (!_.isEmpty(ccList)) {
                body.cc = ccList
            }

            if (!_.isEmpty(bccList)) {
                body.bcc = bccList
            }
            

            let externalAccounts = await findExternalReceivers(StateManager.getToken(), body)

            const toExternalEmails = externalAccounts.to
            const ccExternalEmails = externalAccounts.cc
            const bccExternalEmails = externalAccounts.bcc

            if (!_.isEmpty(toExternalEmails) || !_.isEmpty(ccExternalEmails) || !_.isEmpty(bccExternalEmails)) {
                if (!_.isEmpty(toExternalEmails)) {
                    toExternalEmails.forEach((email) => {
                        let existingIndex = toList.indexOf(email)
                        if (existingIndex > -1) {
                            toList.splice(existingIndex, 1)
                        }
                    })
                }

                if (!_.isEmpty(ccExternalEmails)) {
                    ccExternalEmails.forEach((email) => {
                        let existingIndex = ccList.indexOf(email)
                        if (existingIndex > -1) {
                            ccList.splice(existingIndex, 1)
                        }
                    })
                }

                if (!_.isEmpty(bccExternalEmails)) {
                    bccExternalEmails.forEach((email) => {
                        let existingIndex = bccList.indexOf(email)
                        if (existingIndex > -1) {
                            bccList.splice(existingIndex, 1)
                        }
                    })
                }

                handleCcList(ccList)
                handleToList(toList)
                handleBccList(bccList)

                handleExternalEmailsInState(toExternalEmails, ccExternalEmails, bccExternalEmails)
                handleExternalEncrypted(true)
                toast.dismiss({containerId: TOAST_CONTAINER_ID})

                handlePassphraseModalOpenClose()
            } else {
                return proceedWithSendMessage()
            }
        } else {
            return proceedWithSendMessage()
        }
    }

    const proceedEncryptedMailSendWithPassphrase = async () => {
        if (_.isEmpty(passphrase)) {
            handleIsPassphraseErrorInState(true)
            handlePassphraseErrorInState("Please enter passphrase.")
        } else if (passphrase.length < 8) {
            handleIsPassphraseErrorInState(true)
            handlePassphraseErrorInState("Passphrase should be minimum 8 chars")
        }

        handlePassphraseModalOpenClose()

        toast.loading("registering external users", {containerId: TOAST_CONTAINER_ID})

        let resp = await registerExternalUsers(StateManager.getToken(), {
            to: externalEmails.to,
            cc: externalEmails.cc,
            bcc: externalEmails.bcc,
            passphrase: passphrase
        })

        let originalExternalEmails = []

        if (!_.isEmpty(resp.to)) {

            resp.to.forEach((emailObj) => {
                toList.push(emailObj.ourCreated)
                originalExternalEmails.push(emailObj)
            })
        }

        if (!_.isEmpty(resp.cc)) {
            resp.cc.forEach((emailObj) => {
                ccList.push(emailObj.ourCreated)
                originalExternalEmails.push(emailObj)
            })
        }

        if (!_.isEmpty(resp.bcc)) {

            resp.bcc.forEach((emailObj) => {
                bccList.push(emailObj.ourCreated)
                originalExternalEmails.push(emailObj)
            })
        }

        handleOriginalExternalEmailsInState(originalExternalEmails)

        handleToList(toList)
        handleCcList(ccList)
        handleBccList(bccList)

        toast.dismiss({containerId: TOAST_CONTAINER_ID})

        return proceedWithSendMessage()

    }

    const cancelPassphraseModal = () => {
        toast.dismiss({containerId: TOAST_CONTAINER_ID})
        handlePassphraseModalOpenClose()
    }

    const proceedWithSendMessage = async () => {
        toast.loading("sending message", {containerId: TOAST_CONTAINER_ID})
        try {

            if (_.isEmpty(toList)) {
                toast.error(`Please enter addresses properly.`)
            }

            let backendCallBody = {
                to: toList,
                cc: ccList,
                bcc: bccList,
                message: messageBody,
                subject: subject,
                isHtml,
                attachments,
                isEncrypted: isEncrypt,

            }

            if (externalEncrypted) {
                backendCallBody.externalEncrypted = true
                backendCallBody.passphrase = passphrase
                backendCallBody.externalEmails = originalExternalEmails
            }

            if (isReply || isReplyAll) {
                backendCallBody.replyTo = referenceMessageData.messageId
                backendCallBody.replyToUid = referenceMessageData.uid
                if (!_.isEmpty(referenceMessageData.references))
                    backendCallBody.references = referenceMessageData.references
                else
                    backendCallBody.references = ""
                backendCallBody.isAReply = true
            } else if (isForward) {
                backendCallBody.isForward = true
            }

            if (isEncrypt) {
                let receivers = collectReceivers()
                receivers.push(StateManager.getUserEmail())

                console.log(receivers)

                let publicKeys = await fetchPublicKeys(receivers, StateManager.getToken())
                publicKeys = publicKeys.found
                console.log(publicKeys)
                try {
                    const {body, encryptedSubject} = await encryptMessage({
                        body: messageBody,
                        subject,
                        isHtml,
                        attachments,
                        publicKeys
                    })

                    console.log(body, encryptedSubject)

                    backendCallBody.message = body
                    backendCallBody.subject = encryptedSubject
                    backendCallBody.isEncrypted = true
                    backendCallBody.isHtml = false
                    backendCallBody.attachments = []
                } catch (error) {
                    toast.dismiss({containerId: TOAST_CONTAINER_ID})
                    toast.error("error encrypting message", {containerId: TOAST_CONTAINER_ID})
                    console.error(error)
                }
            }


            console.log(backendCallBody)

            await sendMessageToBackend(backendCallBody, StateManager.getToken())
            handleIsBusy(false)
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.success("message sent", {containerId: TOAST_CONTAINER_ID})
            // setTimeout(() => {
            //     reloadPage()
            // }, 3000)
        } catch (e) {
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.error("error sending message", {containerId: TOAST_CONTAINER_ID})
            console.log(e)
        }
    }

    function collectReceivers() {
        let receivers = []
        toList.forEach((email) => {
            receivers.push(email)
        })
        ccList.forEach((email) => {
            receivers.push(email)
        })
        bccList.forEach((email) => {
            receivers.push(email)
        })
        return receivers
    }

    return (
        <>
            <Header></Header>
            <div className="container-fluid" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                    <div className="col-md-10 col-lg-10 col-xl-10 p-0">
                        <div style={{marginLeft: "15px", marginTop: "15px"}} className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                {/* To Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">To:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {toList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(toList, setToList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            ref={toRef}
                                            value={to}
                                            onChange={handleToChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* CC Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Cc:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {ccList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(ccList, setCcList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            ref={ccRef}
                                            value={cc}
                                            onChange={handleCcChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* BCC Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Bcc:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {bccList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(bccList, setBccList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            ref={bccRef}
                                            value={bcc}
                                            onChange={handleBccChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Subject:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        <input
                                            ref={subjectRef}
                                            type="text"
                                            onChange={(e) => handleSubject(e.target.value)}
                                            placeholder="Subject"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* Message Body */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Message:</label>
                                    <div style={{padding: 0}} className="col-sm-6">
                                        <textarea
                                            ref={mainBodyRef}
                                            value={messageBody}
                                            onChange={(e) => handleBody(e.target.value)}
                                            rows={15}
                                            style={{width: "100%", padding: '10px', borderRadius: '4px'}}
                                        />
                                    </div>
                                </div>

                                <div ref={replyToParentRef} style={{visibility: "hidden"}}
                                     className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Reply To:</label>
                                    <div style={{padding: 0}} className="col-sm-6">
                                                <textarea style={{
                                                    width: '100%',
                                                    padding: '10px',
                                                    borderRadius: '4px'
                                                }} ref={replyToRef} disabled/>
                                    </div>
                                </div>


                                {/* Is HTML Toggle Button */}
                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <button
                                            className={`btn ${isHtml ? 'btn-success' : 'btn-outline-success'}`}
                                            onClick={() => setIsHtml(!isHtml)}
                                            disabled={isBusy}
                                        >
                                            {isHtml ? 'HTML Enabled' : 'Enable HTML'}
                                        </button>
                                    </div>
                                </div>


                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <div className="col-sm-12">
                                            <input
                                                type="checkbox"
                                                checked={isEncrypt}
                                                onChange={handleEncryptChange}
                                            />
                                            <label className="ml-2">Encrypt Message</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <div className="col-sm-12">
                                            <label>Attachments:</label>
                                            <input
                                                type="file"
                                                multiple
                                                onChange={handleFileUpload}
                                            />
                                            {/*<ul className="mt-2">*/}
                                            {/*    {attachments.length > 0 && attachments.map((file, index) => (*/}
                                            {/*        <li key={index}>{file.name}</li>*/}
                                            {/*    ))}*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <button className='btn btn-primary' onClick={sendMessage}
                                                disabled={isBusy}>
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal style={passphraseModalStyles} isOpen={isPassphraseModalOpen}>
                <p>There are Some external recipients and email encrypt option is true. Please enter passphrase for
                    external accounts.</p>
                <StandardTextInput onChange={handlePassphraseInState}
                                   placeholder={"Passphrase, min 8 chars."}/><br></br>
                <StandardButton onClick={proceedEncryptedMailSendWithPassphrase}
                                customStyles={{backgroundColor: "Green"}} innerText={"Proceed"}/>&nbsp;
                <StandardButton customStyles={{backgroundColor: "Red"}} onClick={cancelPassphraseModal}
                                innerText={"Cancel"}/><br></br><br></br>
                {isPassphraseError && (<p style={{color: "red"}}>{passphraseError}</p>)}
            </Modal>
        </>
    )
        ;
}
