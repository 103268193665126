import {useGoogleLogin} from "@react-oauth/google";
import Header from "../components/header";
import Navigation from "../components/navigation";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useState} from "react";
import StateManager from "../redux/StateManager";
import _ from "lodash";
import {
    fetchGmailSyncProgress,
    startGmailSyncProcess,
    resumeGmailSyncProcess,
    pauseGmailSyncProcess,
    cancelGmailSyncProcess
} from "../server-calls";
import {toast} from "react-toastify";
import {TOAST_CONTAINER_ID} from "../utils/constants";
import {initializeSocket} from "../socket";
import {getDeviceId} from "../cookie";

const GmailImport = () => {


    function handleSocket() {
        if (StateManager.cacheMailData().device.IsSetupComplete) {
            let socket = initializeSocket(StateManager.getUserEmail(), StateManager.getToken(), getDeviceId())
            socket.on("message", (message) => {
                message = JSON.parse(message)
                if (message.eventType === "gmailMessageSynced") {
                    console.log(message.totalSynced)
                    handleTotalMessagesStatus(message.totalMessages)
                    handleSyncedMessagesState(message.totalSynced)
                }
            })
        }
    }

    async function startingOperations() {
        toast.loading("loading data", {containerId: TOAST_CONTAINER_ID});
        try {
            let existingProgress = await fetchGmailSyncProgress(StateManager.getToken())
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            if (!_.isEmpty(existingProgress)) {
                handleProgressState(existingProgress)
                handleProgressStatus(existingProgress.status)
                handleTotalMessagesStatus(existingProgress.totalMessages)
                console.log(progress)
                handleSyncedMessagesState(existingProgress.totalSynced)

            }
        } catch (e) {
            console.error(e)
            toast.dismiss({containerId: TOAST_CONTAINER_ID})
            toast.error(e.message)
        }

    }

    useEffect(() => {
        handleSocket()
        startingOperations()
    }, [])

    let [progress, setProgress] = useState({})
    let [progressStatus, setProgressStatus] = useState("none")
    let [totalGmailMessages, setTotalGmailMessages] = useState(0)
    let [syncedGmailMessages, setSyncedGmailMessages] = useState(0)
    let [operationPerformed, setOperationPerformed] = useState(null)


    function handleProgressState(value) {
        progress = value
        setProgress(progress)
    }

    function handleProgressStatus(value) {
        progressStatus = value
        setProgressStatus(progressStatus)
    }

    function handleTotalMessagesStatus(value) {
        totalGmailMessages = value
        setTotalGmailMessages(totalGmailMessages)
    }

    function handleSyncedMessagesState(value) {
        syncedGmailMessages = value
        setSyncedGmailMessages(syncedGmailMessages)
    }

    function handleOperationPerformedState(value) {
        operationPerformed = value
        setOperationPerformed(operationPerformed)
    }

    const useGoogleAuth = () => {
        return useGoogleLogin({
            onSuccess: async (tokenResponse) => {
                try {
                    const {access_token} = tokenResponse;
                    await initSync(access_token)
                    await startingOperations()
                    toast.dismiss({containerId: TOAST_CONTAINER_ID})
                    toast.success("sync started", {containerId: TOAST_CONTAINER_ID})
                } catch (error) {

                    console.error("Auth Error:", error);
                }
            },
            onError: (error) => {
                console.error(error)
                toast.error("login error", {containerId: TOAST_CONTAINER_ID})
            },
            scope: "https://www.googleapis.com/auth/gmail.readonly",
        });
    };

    const googleLogin = useGoogleAuth()

    async function pauseEventClick() {
        handleOperationPerformedState("paused")
        handleProgressStatus("paused")
        toast.loading("pausing sync", {containerId: TOAST_CONTAINER_ID})
        await pauseGmailSyncProcess(StateManager.getToken())
        toast.dismiss({containerId: TOAST_CONTAINER_ID})
        toast.success("sync pause success", {containerId: TOAST_CONTAINER_ID})
    }

    async function initSync(accessToken) {
        toast.loading("auth success starting sync", {containerId: TOAST_CONTAINER_ID})
        await startGmailSyncProcess(StateManager.getToken(), {accessToken})
        toast.dismiss({containerId: TOAST_CONTAINER_ID})
    }

    function resumeEventClick() {
        handleOperationPerformedState("resume")
        googleLogin()
    }

    async function cancelEventClick() {
        handleOperationPerformedState("cancel")
        handleProgressState("cancelled")
        toast.loading("cancelling sync", {containerId: TOAST_CONTAINER_ID})
        await cancelGmailSyncProcess(StateManager.getToken())
        toast.dismiss({containerId: TOAST_CONTAINER_ID})

    }

    async function initFromStartEventClick() {
        handleOperationPerformedState("init")
        googleLogin()
    }

    return (
        <>
            <Helmet>
                <title>Domains</title>
            </Helmet>
            <Header></Header>
            <div className="container-fluid" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                    <div className="col-md-11 col-lg-11 col-xl-11 p-0">
                        <div style={{marginTop: "50px", marginLeft: "50px"}}>
                            {/* eslint-disable-next-line no-mixed-operators */}
                            {(progressStatus === "none" || progressStatus === "completed" || progressStatus === "cancelled") && (<>
                                <button onClick={initFromStartEventClick}>Initiate Gmail Import</button>
                            </>)}

                            {/* eslint-disable-next-line no-mixed-operators */}
                            {(progressStatus === "paused" || progressStatus === "inProgress") && (<>
                                <span>Progress: {syncedGmailMessages}/{totalGmailMessages} done</span><br></br>
                                {progressStatus === "paused" && (
                                    <button onClick={resumeEventClick}>Resume</button>
                                )}

                                {progressStatus === "inProgress" && (
                                    <button onClick={pauseEventClick}>Pause</button>
                                )}

                                &nbsp;&nbsp;
                                <button onClick={cancelEventClick}>Cancel</button>

                            </>)}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GmailImport;
