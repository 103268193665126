export const REGISTER_FORM_RADIO_DATA = {
    generateKeys: {
        text: "Generate Keys",
        value: "generateKeys",
    },
    uploadPublicKey: {
        text: "Upload Public Key",
        value: "uploadPublicKey",
    },
    emailPass: {
        text: "Email Pass",
        value: "emailPass",
    }
}

export const LOCAL_BOXES_CACHE_COLLECTION_NAME = "BOXES"
export const LOCAL_BOXES_CACHE_INDEX_KEY = "name"

export const LOGIN_TYPES = {
    keyUpload: {
        text: "Upload Private Key",
        value: "key",
    },
    emailPass: {
        text: "Email and Password",
        value: "emailPass",
        id:"emailPassOption"
    }
}

export const SORT_OPTIONS = [
    {
        text: "Date",
        value: "date",
    },
    {
        text: "From",
        value: "from",
    }
]

export const SORT_ORDER = [
    {
        text: "Desc",
        value: "descending",
    }, {
        text: "Asc",
        value: "ascending",
    }
]

export const DOMAIN_SORT_OPTIONS = [
    {
        text: "Date Created",
        value: "date",
    },
    {
        text: "Title",
        value: "title",
    }
]

export const DOMAIN_SORT_ORDER = [
    {
        text: "Desc",
        value: "descending",
    }, {
        text: "Asc",
        value: "ascending",
    }
]

export const ALIAS_SORT_OPTIONS = [
    {
        text: "Date Created",
        value: "date",
    },
    {
        text: "Title",
        value: "title",
    }
]

export const ALIAS_SORT_ORDER = [
    {
        text: "Desc",
        value: "descending",
    }, {
        text: "Asc",
        value: "ascending",
    }
]


export const LOGIN_KEY_UPLOAD_TYPES = {
    asImage: {
        text: "As Image",
        value: "asImage",
    },
    asText: {
        text: "As Text",
        value: "asText",
    }
}

export const REGISTER_KEY_DOWNLOAD_TYPES = {
    text: {
        text: "As Text",
        value: "asText",
    },
    image: {
        text: "As Image",
        value: "asImage",
    }
}

export const REGISTER_TYPES = {
    key: "key",
    emailPass: "emailPass"
}

export const VALIDATIONS = {
    PASSPHRASE_LENGTH: 8,
    USERNAME_LENGTH: 3
}

export const KEY_IMAGE_PATH = "key.jpg"

export const CACHE_CONF = {
    setSize: 5,
    sortOption: "uid",
    sortOrder: "asc",
}

export const IDB_VERSION = 1

export const TOAST_CONTAINER_ID = "toast-container"

export const HTML_MESSAGE_IDENTIFIER = "BASE64_HTML_"

export const WORKER_URL = "/worker.js"